export const BASEURL = process.env.REACT_APP_SERVICE_URL;
export const IMAGEPATH = process.env.IMAGE_BASE_URL;

export const PAGE_SIZE = 10;
export const CURRENT_PAGE = 1;
export const DEFAULT_STALE_TIME = 1000 * 60 * 5;

interface JsonData {
    id: number;
    name: string;
    quarterName: string;
    quarter: string;
    value: string;
    table: string;
    orgName: string | null;
    username: string | null;
    year: number;
}

export const rawJsonData: JsonData[] = [
    {
        id: 9154,
        name: 'New Client (Static).2+y - 5y, Male',
        quarterName: 'Apr to Jun 2023',
        quarter: 'Q2',
        value: '3611',
        table: 'T1',
        orgName: null,
        username: null,
        year: 2023
    },
    {
        id: 9155,
        name: 'New Client (Static).29d - 1y, Male',
        quarterName: 'Apr to Jun 2023',
        quarter: 'Q2',
        value: '10502',
        table: 'T1',
        orgName: null,
        username: null,
        year: 2023
    },
    {
        id: 9156,
        name: 'New Client (Static).29d - 1y, Female',
        quarterName: 'Apr to Jun 2023',
        quarter: 'Q2',
        value: '11416',
        table: 'T1',
        orgName: null,
        username: null,
        year: 2023
    },
    {
        id: 9157,
        name: 'New Client (Static).15+y - 19y, Female',
        quarterName: 'Apr to Jun 2023',
        quarter: 'Q2',
        value: '16058',
        table: 'T1',
        orgName: null,
        username: null,
        year: 2023
    },
    {
        id: 9158,
        name: 'New Client (Static).49+y - 65y, Third Gender',
        quarterName: 'Apr to Jun 2023',
        quarter: 'Q2',
        value: '5',
        table: 'T1',
        orgName: null,
        username: null,
        year: 2023
    },
    {
        id: 9159,
        name: 'New Client (Static).10+y - 15y, Female',
        quarterName: 'Apr to Jun 2023',
        quarter: 'Q2',
        value: '5098',
        table: 'T1',
        orgName: null,
        username: null,
        year: 2023
    },
    {
        id: 9160,
        name: 'New Client (Static).19+y - 49y, Female',
        quarterName: 'Apr to Jun 2023',
        quarter: 'Q2',
        value: '79075',
        table: 'T1',
        orgName: null,
        username: null,
        year: 2023
    },
    {
        id: 9161,
        name: 'New Client (Static).65+y, Male',
        quarterName: 'Apr to Jun 2023',
        quarter: 'Q2',
        value: '872',
        table: 'T1',
        orgName: null,
        username: null,
        year: 2023
    },
    {
        id: 9162,
        name: 'New Client (Static).15+y - 19y, Third Gender',
        quarterName: 'Apr to Jun 2023',
        quarter: 'Q2',
        value: '3',
        table: 'T1',
        orgName: null,
        username: null,
        year: 2023
    },
    {
        id: 9163,
        name: 'New Client (Static).0d - 28d, Third Gender',
        quarterName: 'Apr to Jun 2023',
        quarter: 'Q2',
        value: '2',
        table: 'T1',
        orgName: null,
        username: null,
        year: 2023
    },
    {
        id: 9164,
        name: 'New Client (Static).65+y, Female',
        quarterName: 'Apr to Jun 2023',
        quarter: 'Q2',
        value: '1479',
        table: 'T1',
        orgName: null,
        username: null,
        year: 2023
    },
    {
        id: 9165,
        name: 'New Client (Static).49+y - 65y, Female',
        quarterName: 'Apr to Jun 2023',
        quarter: 'Q2',
        value: '4074',
        table: 'T1',
        orgName: null,
        username: null,
        year: 2023
    },
    {
        id: 9166,
        name: 'New Client (Static).19+y - 49y, Third Gender',
        quarterName: 'Apr to Jun 2023',
        quarter: 'Q2',
        value: '13',
        table: 'T1',
        orgName: null,
        username: null,
        year: 2023
    },
    {
        id: 9167,
        name: 'New Client (Static).5+y - 10y, Female',
        quarterName: 'Apr to Jun 2023',
        quarter: 'Q2',
        value: '4187',
        table: 'T1',
        orgName: null,
        username: null,
        year: 2023
    },
    {
        id: 9168,
        name: 'New Client (Static).49+y - 65y, Male',
        quarterName: 'Apr to Jun 2023',
        quarter: 'Q2',
        value: '1605',
        table: 'T1',
        orgName: null,
        username: null,
        year: 2023
    },
    {
        id: 9169,
        name: 'New Client (Static).15+y - 19y, Male',
        quarterName: 'Apr to Jun 2023',
        quarter: 'Q2',
        value: '2057',
        table: 'T1',
        orgName: null,
        username: null,
        year: 2023
    },
    {
        id: 9170,
        name: 'New Client (Static).19+y - 49y, Male',
        quarterName: 'Apr to Jun 2023',
        quarter: 'Q2',
        value: '8396',
        table: 'T1',
        orgName: null,
        username: null,
        year: 2023
    },
    {
        id: 9171,
        name: 'New Client (Static).2+y - 5y, Female',
        quarterName: 'Apr to Jun 2023',
        quarter: 'Q2',
        value: '4447',
        table: 'T1',
        orgName: null,
        username: null,
        year: 2023
    },
    {
        id: 9172,
        name: 'New Client (Static).5+y - 10y, Male',
        quarterName: 'Apr to Jun 2023',
        quarter: 'Q2',
        value: '3212',
        table: 'T1',
        orgName: null,
        username: null,
        year: 2023
    },
    {
        id: 9173,
        name: 'New Client (Static).10+y - 15y, Third Gender',
        quarterName: 'Apr to Jun 2023',
        quarter: 'Q2',
        value: '380',
        table: 'T1',
        orgName: null,
        username: null,
        year: 2023
    },
    {
        id: 9174,
        name: 'New Client (Static).1+y - 2y, Female',
        quarterName: 'Apr to Jun 2023',
        quarter: 'Q2',
        value: '5751',
        table: 'T1',
        orgName: null,
        username: null,
        year: 2023
    },
    {
        id: 9175,
        name: 'New Client (Static).10+y - 15y, Male',
        quarterName: 'Apr to Jun 2023',
        quarter: 'Q2',
        value: '2148',
        table: 'T1',
        orgName: null,
        username: null,
        year: 2023
    },
    {
        id: 9176,
        name: 'New Client (Static).0d - 28d, Female',
        quarterName: 'Apr to Jun 2023',
        quarter: 'Q2',
        value: '5939',
        table: 'T1',
        orgName: null,
        username: null,
        year: 2023
    },
    {
        id: 9177,
        name: 'New Client (Static).0d - 28d, Male',
        quarterName: 'Apr to Jun 2023',
        quarter: 'Q2',
        value: '5475',
        table: 'T1',
        orgName: null,
        username: null,
        year: 2023
    },
    {
        id: 9178,
        name: 'New Client (Static).1+y - 2y, Male',
        quarterName: 'Apr to Jun 2023',
        quarter: 'Q2',
        value: '4530',
        table: 'T1',
        orgName: null,
        username: null,
        year: 2023
    }
];
