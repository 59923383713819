import { useSelector } from 'react-redux';

import { Theme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// store
import { DefaultRootStateProps } from 'types';

// defaultTheme
import themes from 'themes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
// import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';

// auth provider
// import { FirebaseProvider } from 'contexts/FirebaseContext';
import { JWTProvider } from 'contexts/JWTContext';
// import { Auth0Provider } from 'contexts/Auth0Context';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { QueryClientProvider } from '@tanstack/react-query';
import { client } from './react_query/query-client-configuration';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Toaster } from 'react-hot-toast';

declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state: DefaultRootStateProps) => state.customization);

    return (
        <QueryClientProvider client={client}>
            <StyledEngineProvider injectFirst>
                <Toaster
                    position="top-right"
                    reverseOrder={false}
                    gutter={8}
                    toastOptions={{
                        duration: 4000,
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff'
                        }
                    }}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <ThemeProvider theme={themes(customization)}>
                        <CssBaseline />
                        {/* RTL layout */}
                        {/* <RTLLayout> */}
                        <Locales>
                            <NavigationScroll>
                                <JWTProvider>
                                    <>
                                        <Routes />
                                        <Snackbar />
                                    </>
                                </JWTProvider>
                            </NavigationScroll>
                        </Locales>
                        {/* </RTLLayout> */}
                    </ThemeProvider>
                </LocalizationProvider>
            </StyledEngineProvider>
            <ReactQueryDevtools />
        </QueryClientProvider>
    );
};

export default App;
