import React, { createContext, useEffect, useReducer } from 'react';
// import config from '../config';
// third-party
import jwtDecode from 'jwt-decode';

// reducer - state management
import accountReducer from 'store/accountReducer';
import { LOGIN, LOGOUT } from 'store/actions';

// project imports
import useSnackbar from 'hooks/useSnackbar';
import { useDispatch } from 'react-redux';
import { initialLoginContextProps, KeyedObject } from 'types';
import { JWTContextType, UserAttributeType } from 'types/auth';
import Loader from 'ui-component/Loader';
import { loginURL, resetPasswordRequestURL } from 'utils/serverUrls';
import axiosServices from '../utils/axiosServices';

// constant
const initialState: initialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const verifyToken: (st: string) => boolean = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded: KeyedObject = jwtDecode(serviceToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};

export const setSession = (serviceToken?: string | null) => {
    const rememberMe = JSON.parse(localStorage.getItem('rememberMe') as any);
    if (rememberMe && serviceToken) {
        localStorage.setItem('token', serviceToken);
    } else if (!rememberMe && serviceToken) {
        sessionStorage.setItem('token', serviceToken);
    } else {
        localStorage.removeItem('token');
        sessionStorage.removeItem('token');
        delete axiosServices.defaults.headers.common.Authorization;
    }
};

export const setRefreshToken = (refreshToken?: string | null) => {
    const rememberMe = JSON.parse(localStorage.getItem('rememberMe') as any);
    if (refreshToken && rememberMe) {
        localStorage.setItem('refreshToken', refreshToken);
    } else if (!rememberMe && refreshToken) {
        sessionStorage.setItem('refreshToken', refreshToken);
    } else {
        localStorage.removeItem('refreshToken');
        sessionStorage.removeItem('refreshToken');
        delete axiosServices.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const reduxDispatch = useDispatch();

    const snackbarMsg = useSnackbar();

    const setUserStateData = async (serviceToken: any) => {
        const decoded: KeyedObject = jwtDecode(serviceToken);

        // ========> Nahian code start
        // console.log('decoded', decoded);
        // const response = await axiosServices.get(`${userProfileByKCIdURL}`, { params: { kcId: decoded.sub } });
        // console.log('login response: ', response.data.data);
        // if (serviceToken) {
        //     const userResponse = await _deserailize(response.data);
        //     console.log('userResponse', userResponse);
        //     alert(JSON.stringify(userResponse, null, 2));

        //     dispatch({
        //         type: LOGIN,
        //         payload: {
        //             isLoggedIn: true,
        //             user: {
        //                 ...userResponse,
        //                 ...jwtDecode(serviceToken)
        //             }
        //         }
        //     });

        //     reduxDispatch({
        //         type: LOGIN,
        //         payload: {
        //             isLoggedIn: true,
        //             user: {
        //                 ...userResponse,
        //                 ...decoded
        //             }
        //         }
        //     });
        // } else {
        //     console.log('logging out ');
        //     dispatch({
        //         type: LOGOUT
        //     });
        // }
        // ========> Nahian code end

        if (decoded) {
            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: true,
                    user: {
                        ...jwtDecode(serviceToken)
                    }
                }
            });

            reduxDispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: true,
                    user: {
                        ...decoded
                    }
                }
            });
        } else {
            dispatch({
                type: LOGOUT
            });
        }
    };

    useEffect(() => {
        const init = async () => {
            try {
                const rememberMe = JSON.parse(localStorage.getItem('rememberMe') as any);
                let serviceToken = null;
                if (rememberMe) {
                    serviceToken = localStorage.getItem('token');
                } else {
                    serviceToken = sessionStorage.getItem('token');
                }
                if (serviceToken && verifyToken(serviceToken)) {
                    setSession(serviceToken);
                    setUserStateData(serviceToken);
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
    }, []);

    const login = async (username: string, password: string) => {
        // setUserStateData();
        try {
            const response = await axiosServices.post(loginURL, { userName: username, password });
            console.log(response.data);

            // if (response.data.token) {
            //     setSession(response.data.token);
            //     setRefreshToken(response.data.refresh_token.token);
            //     setUserStateData(response.data.token);
            // }
            if (response.data.value._value.token) {
                setSession(response.data.value._value.token);
                setRefreshToken(response.data.value._value.refreshToken);
                setUserStateData(response.data.value._value.token);
            } else {
                snackbarMsg('Invalid Request', 'error');
            }
        } catch (error) {
            snackbarMsg('Failed to login!', 'error');
            console.log(error);
        }
    };

    const register = async (studentData: UserAttributeType) => {
        const response = await axiosServices.post('users/students/create/', {
            data: {
                type: 'User',
                attributes: studentData
            }
        });
        const userData = response.data.data;
        let users: any[] = [
            {
                id: userData?.id,
                email: userData?.attributes?.profile?.email,
                name: userData?.attributes?.profile?.name
            }
        ];

        if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
            const localUsers = window.localStorage.getItem('users');
            users = [
                ...JSON.parse(localUsers!),
                {
                    id: userData?.id,
                    email: userData?.attributes?.profile?.email,
                    name: userData?.attributes?.profile?.name
                }
            ];
        }

        window.localStorage.setItem('users', JSON.stringify(users));
    };

    const logout = () => {
        setSession(null);
        dispatch({ type: LOGOUT });
    };

    const resetPassword = async (email: string) =>
        axiosServices.post(resetPasswordRequestURL, {
            email,
            url: `${window.location.origin}/new-password`
        });

    const updateProfile = () => {};

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile }}>{children}</JWTContext.Provider>
    );
};

export default JWTContext;
