// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconBasket,
    IconBuildingCommunity,
    IconCalendar,
    IconClipboardCheck,
    IconFileCertificate,
    IconLayoutKanban,
    IconMail,
    IconMessages,
    IconNfc,
    IconSchool,
    IconUserCheck,
    IconUsers
} from '@tabler/icons';

// constant
const icons = {
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc,
    IconSchool,
    IconBuildingCommunity,
    IconUsers,
    IconClipboardCheck,
    IconFileCertificate
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const application = {
    id: 'application',
    title: <FormattedMessage id="application" />,
    type: 'group',
    children: [
        {
            id: 'users',
            title: <FormattedMessage id="users" />,
            type: 'item',
            icon: icons.IconUserCheck,
            url: '/users'
        },
        {
            id: 'organizations',
            title: <FormattedMessage id="organizations" />,
            type: 'item',
            icon: icons.IconBuildingCommunity,
            url: '/organizations'
        },
        {
            id: 'notification',
            title: <FormattedMessage id="notification" />,
            type: 'item',
            icon: icons.IconMessages,
            url: '/notification'
        },
        {
            id: 'student',
            title: <FormattedMessage id="student" />,
            type: 'item',
            icon: icons.IconSchool,
            url: '/students'
        },
        {
            id: 'batch',
            title: <FormattedMessage id="batch" />,
            type: 'item',
            icon: icons.IconUsers,
            url: '/batches'
        }
    ]
};

export default application;
