import defaultImage from 'assets/images/placeholder/default.png';
import { BASEURL } from './Constants';

export const fullFilePath = (picPath: string) => {
    if (!picPath) {
        return defaultImage;
    }
    if (picPath.includes('http')) {
        return picPath;
    }
    return `${BASEURL}${picPath
        ?.split('/')
        .filter((v) => v !== '')
        .join('/')}`;
};

export const checkNullInfo = (info: string | null | undefined) => {
    if (info) {
        return info;
    }
    return '---';
};

export const getFieldLabel = () => {};

export const getToken = (storageName: string) => {
    let token = null;
    const rememberMe = JSON.parse(localStorage.getItem('rememberMe') as any);

    if (rememberMe) {
        token = window.localStorage.getItem(storageName);
    } else {
        token = window.sessionStorage.getItem(storageName);
    }
    return token;
};

export const setToken = (storageName: string, value: string) => {
    let token = null;
    const rememberMe = JSON.parse(localStorage.getItem('rememberMe') as any);

    if (rememberMe) {
        token = window.localStorage.setItem(storageName, value);
    } else {
        token = window.sessionStorage.setItem(storageName, value);
    }
    return token;
};

export const errMsg = (ex: any) => {
    if (ex.response.data.errors) {
        return ex.response.data.errors.map((e: any) => e.detail).join(', ');
    }
    return ex.response.data;
};

export const getDayNumberFromLabel = (dayLabel: string) => {
    switch (dayLabel) {
        case 'Mon':
            return 1;
        case 'Tue':
            return 2;
        case 'Wed':
            return 3;
        case 'Thu':
            return 4;
        case 'Fri':
            return 5;
        case 'Sat':
            return 6;
        case 'Sun':
            return 7;
        default:
            return -1; // Handle unknown labels gracefully if needed
    }
};

export const getLabelFromDayNumber = (dayNumber: number) => {
    switch (dayNumber) {
        case 1:
            return 'Monday';
        case 2:
            return 'Tuesday';
        case 3:
            return 'Wednesday';
        case 4:
            return 'Thursday';
        case 5:
            return 'Friday';
        case 6:
            return 'Saturday';
        case 7:
            return 'Sunday';
        default:
            return 'Invalid Day Number'; // Handle unknown numbers gracefully if needed
    }
};
